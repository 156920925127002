import logo from "../images/logo_trasp.png";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSignInMutation } from "../services/authApi";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../features/auth/authSlice";
import { ErrorBanner } from "../components/custom/ErrorBanner";
import Cookies from "js-cookie";
import { getToken } from "../functions/get_token";

export const LoginPage = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [signIn, { data, isLoading, isSuccess, isError, error }] =
    useSignInMutation();
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAuth(data));
      //Salvo access token
      Cookies.set("token", data.accessToken, { expires: 7, secure: true });
      console.log(getToken("token"));
      setEmail("");
      setPassword("");
      navigate(
        data.roles.includes(1) || data.roles.includes(2)
          ? "/cash-flow"
          : "/orders-to-confirm",
        { replace: true }
      );
    } else if (isError) {
      console.log(error)
      //const { message } = error.data;
      const { data } = error;
      setErrorMessage(data.message);
      setShowErrorMessage(true);
    }
  }, [isLoading]);

  const onClickLogin = async (e) => {
    e.preventDefault(e);
    await signIn({ email, password });
  };
  const handleEmail = (email) => {
    setEmail(email.target.value);
  };
  const handlePassword = async (password) => {
    setPassword(password.target.value);
  };

  return (
    <section className="h-screen px-4">
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="w-60" src={logo} alt="Your Company" />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Benvenuto/a,
              </h2>
              <p className="mt-2  leading-6 text-gray-500 text-xl">
                Accedi per continuare.
              </p>
            </div>

            <div className="mt-10">
              <div>
                <form onSubmit={(e) => onClickLogin(e)} className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => handleEmail(e)}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => handlePassword(e)}
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Link
                        to="/recovery-password"
                        className="font-semibold text-green-facile hover:text-green-facile"
                      >
                        Password dimenticata?
                      </Link>
                    </div>

                    <div className="text-sm leading-6"></div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-green-facile px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-facile focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-facile"
                    >
                      Accedi
                    </button>
                    <span className="mt-3 leading-6 text-gray-500 text-sm">
                      Non hai un account?{" "}
                      <Link
                        to={"/register"}
                        className={"text-green-facile hover:text-green-facile"}
                      >
                        Registrati
                      </Link>
                    </span>
                  </div>
                </form>
              </div>

              <div className="mt-10">
                <div className="relative"></div>

                <div className="mt-6 grid grid-cols-2 gap-4"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
      <ErrorBanner
        message={errorMessage}
        show={showErrorMessage}
        setShow={setShowErrorMessage}
      />
    </section>
  );
};
//if (typeof PDFViewerApplication !== 'undefined' && PDFViewerApplication.initialized && PDFViewerApplication.pdfViewer.pageViewsReady)
