import { useEffect, useState } from "react";
import ShopifyKeyForm from "../components/ShopifyKeyForm";
import EbayLogo from "../ebay-logo.png"
import ShopifyLogo from "../shopify-logo.png"
import axios from "axios";
import { IntegrationPlatformEnum } from "../enums/IntegrationPlatformEnum";
import { useParams } from "react-router-dom";
import { DEV, ebayUrl, ebayUrlSandbox } from "../config/baseUrl";
import EbayList from "../components/integration/EbayList";


export const IntegrationPage = () => {
  const { platformId } = useParams()
  const [platform, setPlatform] = useState(IntegrationPlatformEnum[platformId] ?? IntegrationPlatformEnum["Shopify"])


  return (
    <main className="px-4">
      <section id="buttons-platform" className="flex gap-2 mb-4">
        <a 
        onClick={() => setPlatform(IntegrationPlatformEnum.Shopify)} 
        className={`shadow-md py-2 px-4 rounded-md text-center m-2  hover:bg-gray-50 cursor-pointer ${platform == IntegrationPlatformEnum.Shopify ? " border-solid border-2 border-green-700" : ""}`}>
          <img src={ShopifyLogo} />
        <h4>Shopify</h4>
      </a>
      <a 
      onClick={() => setPlatform(IntegrationPlatformEnum.Ebay)} 
      className={`shadow-md py-2 px-4 rounded-md text-center m-2 hover:bg-gray-50 cursor-pointer ${platform == IntegrationPlatformEnum.Ebay ? "border-solid border-2 border-green-700" : ""}`}>
      <img src={EbayLogo} />
        <h4>Ebay</h4>
      </a>
      </section>
      {platform == IntegrationPlatformEnum.Shopify ? <ShopifyKeyForm /> : <></>}
      {platform == IntegrationPlatformEnum.Ebay ? 
      <section id="integration-ebay-section">
        <a className="uppercase bg-gray-100 text-gray-800 px-8 py-4 rounded-md" target="_blank" href={DEV == "SI" ? ebayUrlSandbox : ebayUrl}>aggiungi negozio ebay</a>
        <div className="my-5"></div>
        <EbayList />
      </section>
      : <></>}
    </main>
  );
};
