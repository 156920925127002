import React, { useEffect } from 'react'
import PopupDialog from './custom/PopupDialog'
import { useGetUserShippingPriceListQuery } from '../services/shippingPriceListApi'
import { LoadingIcon } from './custom/LoadingIcon'
import RowTable from './custom/RowTable'
import CellRowTable from './custom/CellRowTable'
import BodyTable from './custom/BodyTable'
import ListTable from './custom/ListTable'
import HeaderTable from './custom/HeaderTable'
import HiddenHeaderColTable from './custom/HiddenHeaderColTable'
import HeaderColTable from './custom/HeaderColTable'
import { skipToken } from '@reduxjs/toolkit/query'

function PopupShippingPriceList({ open, setOpen }) {

    const { data: shippingPriceList, isLoading, isSuccess, isError, error } = useGetUserShippingPriceListQuery({
        skip: !open,
        refetchOnFocus: true
    })
  return (
    <PopupDialog open={open} setOpen={setOpen}>
        <h4>Listino Spedizione</h4>
        {isLoading ? <LoadingIcon /> : isSuccess ? 
        <ListTable className="mt-2">
            <HeaderTable>
            <RowTable>
                <HeaderColTable className={"text-center"}>DA</HeaderColTable>
                <HeaderColTable className={"text-center"}>A</HeaderColTable>
                <HeaderColTable className={"text-center"}>Contrassegno</HeaderColTable>
                <HeaderColTable className={"text-center"}>Express</HeaderColTable>
            </RowTable>
            </HeaderTable>
            <BodyTable>
                {shippingPriceList?.shippingPriceListWeightRange.map((spl) => (
                <RowTable key={spl.id}>
                    <CellRowTable textAlign={"text-center"}>{spl.weightFrom}</CellRowTable>
                    <CellRowTable textAlign={"text-center"}>{spl.weightTo}</CellRowTable>
                    <CellRowTable textAlign={"text-center"}>{spl.codShippingCost}</CellRowTable>
                    <CellRowTable textAlign={"text-center"}>{spl.expressShippingCost}</CellRowTable>
                </RowTable>
                )) }
            </BodyTable>
      </ListTable>
        : <></>}
    </PopupDialog>
  )
}

export default PopupShippingPriceList