import React from "react";
import { LoadingIcon } from "./custom/LoadingIcon";

function ButtonBasic({
  textButton,
  isLoading = false,
  Icon,
  disabled,
  onClick,
}) {
  return (
    <button
      onClick={(e) => onClick(e)}
      disabled={disabled}
      type="button"
      className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
    >
      {textButton}
      {isLoading ? (
        <LoadingIcon size={"h-4 w-4"} color={"text-white"} />
      ) : Icon == undefined ? (
        <></>
      ) : (
        <Icon className="h-4 w-4" />
      )}
    </button>
  );
}

export default ButtonBasic;
