import React from "react";
import { useGetAllCustomerProductInventoryQuery } from "../services/customerProductInventoryApi";
import { LoadingIcon } from "../components/custom/LoadingIcon";
import CustomerProductList from "../components/customer-product-inventory/CustomerProductList";

function CustomerProductInventoryPage() {
  const {
    data: customerProducts,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllCustomerProductInventoryQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  return (
    <main className="px-4">
      {isLoading ? (
        <LoadingIcon size={"h-6 w-6"} color={"text-black"} />
      ) : isSuccess ? (
        <CustomerProductList products={customerProducts} />
      ) : (
        <></>
      )}
    </main>
  );
}

export default CustomerProductInventoryPage;
