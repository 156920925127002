import { userApi } from "./userApi";

const stripeApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    createPaymentIntent: builder.mutation({
      query: (amount) => ({
        url: `/stripe-api/create-payment-intent`,
        method: "POST",
        body: amount,
      }),
    }),
    cancelSubscription: builder.mutation({
      query: () => ({
        url: `/stripe-api/remove-subscription`,
        method: "POST",
      }),
      invalidatesTags: ["Subscription", "SubscriptionPayment"],
    }),
    getConfig: builder.query({
      query: () => `stripe-api/config`,
    }),
    getAllSubscriptions: builder.query({
      query: () => `subscription/all-subscriptions`,
    }),
    getSubscription: builder.query({
      query: () => `stripe-api/get-subscription`,
      providesTags: ["Subscription"],
    }),
    getAllPaymentMethod: builder.query({
      query: () => `stripe-api/payment-method`,
      providesTags: ["SubscriptionPayment"],
    }),
    paySubscription: builder.mutation({
      query: ({ paymentMethod, subPrice }) => ({
        url: `/stripe-api/pay-subscription`,
        method: "POST",
        body: { paymentMethod, subPrice },
      }),
      invalidatesTags: ["Subscription", "SubscriptionPayment"],
    }),
    payProducts: builder.mutation({
      query: (order) => ({
        url: `/stripe-api/pay-products`,
        method: "POST",
        body: order,
      }),
      invalidatesTags: ["Subscription", "SubscriptionPayment"],
    }),
    payBalanceAmount: builder.mutation({
      query: () => ({
        url: `/stripe-api/pay-balance-amount`,
        method: "POST",
      }),
    }),
    payProductsInCart: builder.mutation({
      query: ({ productsCatalogInCart, shipmentCost }) => ({
        url: `/stripe-api/pay-products-in-cart`,
        method: "POST",
        body: { productsCatalogInCart, shipmentCost },
      }),
      invalidatesTags: ["Subscription", "SubscriptionPayment"],
    }),
    upgradeSubscription: builder.mutation({
      query: ({ subscriptionPrice }) => ({
        url: `/stripe-api/upgrade-subscription`,
        method: "POST",
        body: { subscriptionPrice },
      }),
      invalidatesTags: ["Subscription", "SubscriptionPayment"],
    }),
    addPaymentMethod: builder.mutation({
      query: ({ paymentMethod }) => ({
        url: `/stripe-api/add-payment-method`,
        method: "POST",
        body: { paymentMethod },
      }),
      invalidatesTags: ["SubscriptionPayment"],
    }),
    setDefaultPaymentMethod: builder.mutation({
      query: ({ paymentMethod }) => ({
        url: `/stripe-api/payment-method/set-default`,
        method: "POST",
        body: { paymentMethod },
      }),
      invalidatesTags: ["SubscriptionPayment"],
    }),
    deletePaymentMethod: builder.mutation({
      query: ({ paymentMethod }) => ({
        url: `/stripe-api/payment-method/delete`,
        method: "POST",
        body: { paymentMethod },
      }),
      invalidatesTags: ["SubscriptionPayment"],
    }),
    getPaymentStatus: builder.query({
      query: (sessionId) => `stripe-api/payment-status/${sessionId}`,
    }),
  }),
  overrideExisting: false,
});

export const {
  //useMakePaymentMutation,
  usePaySubscriptionMutation,
  usePayProductsMutation,
  useCreatePaymentIntentMutation,
  useGetConfigQuery,
  useGetAllPaymentMethodQuery,
  useCancelSubscriptionMutation,
  useGetSubscriptionQuery,
  useAddPaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useUpgradeSubscriptionMutation,
  useLazyGetPaymentStatusQuery,
  useGetAllSubscriptionsQuery,
  usePayProductsInCartMutation,
  usePayBalanceAmountMutation
} = stripeApi;
