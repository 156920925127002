
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import React, { useState, useEffect, useRef } from "react";
import { useSavePrivacyPolicyMutation } from "../services/privacyPolicyApi";
import { getPublicClientIp } from "../functions/ip_address";
import { LoadingIcon } from "./custom/LoadingIcon";

function PopupPrivacyPolicy({ children, open, setOpen, padding }) {
  const [savePrivacyPolicy, { isLoading }] = useSavePrivacyPolicyMutation();


  const [isReaded, setIsReaded] = useState(false);

  // Funzione di gestione dell'accettazione della policy
  const handleAccept = async () => {
    const publicIpAddress = await getPublicClientIp();
    savePrivacyPolicy({
      ipAddress: publicIpAddress,
      isReaded,
    }).then((res) => {
      if (res.error) {
        return;
      }
      setOpen(false);
      window.location.reload();
    });
  };

  return (
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={() => {}}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={`max-h-256 max-w-7xl overflow-auto relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ${
                  padding == undefined ? "px-4 pb-4 pt-5 sm:p-6" : padding
                } `}
              >
                {/* Container che scorre */}
                <div
                  //ref={measuredRef}
                  className="max-h-96 max-w-96 overflow-auto"
                  id="container-popup-policy"
                >
                  {children}
                </div>
                <div className="flex items-center mt-2">
                  <input
                    required={true}
                    value={isReaded}
                    onChange={() => {
                      setIsReaded(!isReaded);
                    }}
                    id="accept-privacy"
                    name="accept-privacy"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="accept-privacy"
                    className="ml-3 block text-sm leading-6 text-gray-900"
                  >
                    Confermo di aver letto le privacy policy.
                  </label>
                </div>
                <button
                  className={`mt-4 px-4 py-2 bg-green-600 text-white rounded flex gap-2 items-center
                    ${
                      isReaded ? "opacity-100" : "opacity-50 cursor-not-allowed"
                    }
                  `}
                  onClick={handleAccept}
                  disabled={!isReaded || isLoading}
                >
                  Accetta Privacy Policy{" "}
                  <span>
                    {isLoading ? (
                      <LoadingIcon size={"h-4 w-4"} color={"text-white"} />
                    ) : (
                      <></>
                    )}
                  </span>
                </button>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default PopupPrivacyPolicy;
