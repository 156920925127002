import { useEffect, useState } from "react";
import {
  useGetOrderPagesMutation,
  useGetShopifyOrdersMutation,
} from "../services/userApi";
import { SimpleButton } from "../components/SimpleButton";
import { ErrorBanner } from "../components/custom/ErrorBanner";
import { LoadingIcon } from "../components/custom/LoadingIcon";
import {
  CheckIcon,
  CreditCardIcon,
  DocumentIcon,
  DownloadIcon,
  PrinterIcon,
  UploadIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { CreateLdvButton } from "../components/CreateLdvButton";
import { usePrintWaybillShipmentsMutation } from "../services/shipmentApi";
import { PrintPdfButton } from "../components/PrintPdfButton";
import ListShopifyOrder from "../components/order/NewShopifyOrderList/ListShopifyOrder";
import NavShopifyOrders from "../components/order/NewShopifyOrderList/NavShopifyOrder";
import { SearchIcon } from "@heroicons/react/solid";
import DropDown from "../components/order/NewShopifyOrderList/DropDown";
import moment from "moment";
import ToggleButton from "../components/custom/ToggleButton";
import {
  useImportAllOrderMutation,
  useInsertAllByXlsxMutation,
  useUpdateFulfillmentRequestMutation,
} from "../services/orderApi";
import { FulfillmentStatus } from "../enums/FulfillmentStatus";
import SimpleNotification from "../components/SimpleNotification";
import * as XLSX from "xlsx";
import ButtonFileCustom from "../components/custom/ButtonFileCustom";
import PopupTermsAndConditions from "../components/PopupTermsAndConditions";
import TermsAndConditionsText from "../components/TermsAndConditionsText";
import Select from "react-select";
import { useGetAllStoreQuery } from "../services/shopifyApi";
import { useGetAllCustomerQuery } from "../services/customerApi";
import PopupDialog from "../components/custom/PopupDialog";
import BadgeErrorMessage from "../components/custom/BadgeErrorMessage";
import PopupShippingPriceList from "../components/PopupShippingPriceList";
import PopupSelectStore from "../components/PopupSelectStore";
const options = [
  { id: 0, name: "Tutto" },
  { id: 1, name: "Si" },
  { id: 2, name: "No" },
];

export const OrderPage = () => {
  const [showImportXLSXPopup, setShowImportXLSXPopup] = useState(false);
  const [customerXLSX, setCustomerXLSX] = useState();
  const [errorImportXLSX, setErrorImportXLSX] = useState();
  const [ordersXLSX, setOrdersXLSX] = useState([]);

  const [showShippingPriceList, setShowShippingPriceList] = useState(false);

  const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);
  const [showSuccessUpdateFulfillment, setShowSuccessUpdateFulfillment] =
    useState(false);
  const [showOrderProducts, setShowOrderProducts] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [selectedOrderToPrint, setSelectedOrderToPrint] = useState([]);
  const [page, setPage] = useState(1);
  const [pageRows, setPageRows] = useState(20);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [printingMode, setPrintingMode] = useState(false);
  const [ldvGenerated, setLdvGenerated] = useState([]);
  const [isUpdatingFulfill, setIsUpdatingFulfill] = useState(false);
  const [messageNotification, setMessageNotification] = useState("");
  const [successNotification, setSuccessNotification] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [ordersToPay, setOrdersToPay] = useState([]);
  /* FILTRI */
  const [showFailedShipments, setShowFailedShipments] = useState(false);
  const [filterCashOnDelivery, setFilterCashOnDelivery] = useState(options[0]);
  const [filterPaidOrder, setFilterPaidOrder] = useState(options[0]);
  const [filterShippingSent, setFilterShippingSent] = useState(options[0]);
  const [filterIsPrinted, setFilterIsPrinted] = useState(options[0]);
  const [filterStore, setFilterStore] = useState(null);
  const [filterCustomer, setFilterCustomer] = useState();
  const [searchText, setSearchText] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(
    moment().add(-30, "days").format("yyyy-MM-DD")
  );
  const [filterEndDate, setFilterEndDate] = useState(
    moment().format("yyyy-MM-DD")
  );
  /* FILTRI */

  var {
    stores,
    isLoading: isLoadingStores,
    isSuccess: isSuccessStores,
    isError: isErrorStores,
    error: errorStores,
  } = useGetAllStoreQuery(undefined, {
    selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
      var stores = data?.map((store) => ({
        value: store,
        label:
          (store?.aliasStore ?? "") == ""
            ? store?.storeName
            : store?.aliasStore,
      }));

      return {
        stores,
        isLoading,
        isSuccess,
        isError,
        error,
      };
    },
  });

  var {
    customers,
    isLoading: loadingCustomers,
    isSuccess: successCustomers,
    isError: errorCustomersEvent,
    error: errorCustomersText,
  } = useGetAllCustomerQuery("%20", {
    selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
      var customers = data?.map((cus) => ({
        value: cus,
        label: `${cus?.name} ${cus?.surname}`,
      }));
      return {
        customers,
        isLoading,
        isSuccess,
        isError,
        error,
      };
    },
  });

  const [getOrderPages, { data: ordersLength }] = useGetOrderPagesMutation();

  const auth = useSelector((state) => state.auth);
  const [
    getShopifyOrders,
    { data: shopifyOrders, error, isLoading, isSuccess, isError },
  ] = useGetShopifyOrdersMutation(); //useLazyGetShopifyOrdersQuery();

  const [
    importAllOrder,
    {
      isLoading: loadingImport,
      isSuccess: successImport,
      isError: importError,
      error: errorImport,
    },
  ] = useImportAllOrderMutation();

  const [
    insertAllByXlsx,
    {
      isLoading: loadingInsertByXlsx,
      isSuccess: successInsertByXlsx,
      isError: errorInsertByXlsxEvent,
      error: errorInsertByXlsxText,
    },
  ] = useInsertAllByXlsxMutation();

  const getAllOrders = () => {
    const filter = {
      searchText,
      toPrint: printingMode,
      isPrinted:
        filterIsPrinted.id == 1
          ? true
          : filterIsPrinted.id == 2
          ? false
          : undefined,
      isCashOnDelivery:
        filterCashOnDelivery.id == 1
          ? true
          : filterCashOnDelivery.id == 2
          ? false
          : undefined,
      isPaidOrder:
        filterPaidOrder.id == 1
          ? true
          : filterPaidOrder.id == 2
          ? false
          : undefined,
      isSentShipping:
        filterShippingSent.id == 1
          ? true
          : filterShippingSent.id == 2
          ? false
          : undefined,
      dateRange: { startDate: filterStartDate, endDate: filterEndDate },
      showFailedShipments: showFailedShipments,
      shopifyStoreId: filterStore?.value?.id ?? 0,
      codfacileCustomer: filterCustomer?.value?.id ?? 0,
    };
    getOrderPages({ filter });
    getShopifyOrders({
      page,
      pageRows,
      filter: filter,
    });
  };

  useEffect(() => {
    if (!isUpdatingFulfill) getAllOrders();
  }, [
    page,
    pageRows,
    filterCashOnDelivery,
    filterShippingSent,
    filterPaidOrder,
    filterStartDate,
    filterEndDate,
    printingMode,
    filterIsPrinted,
    isUpdatingFulfill,
    showFailedShipments,
    filterStore,
    filterCustomer,
  ]);

  const [
    updateFulfillment,
    {
      data: dataUpdateFulfillment,
      isLoading: loadingUpdateFulfillment,
      isSuccess: successUpdateFulfillment,
      isError: errorUpdateFulfillmentEvent,
      error: errorUpdateFulfillmentText,
    },
  ] = useUpdateFulfillmentRequestMutation();

  const [
    printWaybillShipments,
    {
      data: base64Pdf,
      isLoading: printLoading,
      isSuccess: printSuccess,
      isError: isPrintError,
      error: printError,
    },
  ] = usePrintWaybillShipmentsMutation();

  const handleAddItem = (checked, item) => {
    let items = [...selectedOrder];
    var ids = items.map((ele) => ele.id);
    if (checked) items.push(item);
    else {
      var index = ids.indexOf(item.id);
      items.splice(index, 1);
    }

    setSelectedOrder(items);
  };

  const handleItemToPrint = (e, item) => {
    let items = [...selectedOrderToPrint];
    var ids = items.map((ele) => ele.id);
    if (e.target.checked) items.push(item);
    else {
      var index = ids.indexOf(item.id);
      items.splice(index, 1);
    }

    setSelectedOrderToPrint(items);
  };

  const handleSelectAllItemToPrint = (e) => {
    if (
      selectedOrderToPrint.length ==
      shopifyOrders?.filter((so) => so.tracking_number).length
    ) {
      setSelectedOrderToPrint([]);
    } else {
      setSelectedOrderToPrint(
        shopifyOrders?.filter((so) => so.tracking_number)
      );
    }
  };

  const onClick = async () => {
    if (auth.termsAndConditions == false) {
      setOpenTermsAndConditions(true);
      return;
    }
    const ordersToFulfill = selectedOrder.filter(
      (order) =>
        order.tracking_number == null &&
        order.fullfilment_status !== "Completato"
    );
    if ((ordersToFulfill?.length ?? 0) == 0) return;
    updateFulfillment({
      orders: ordersToFulfill,
      toFulfill: true,
      fulfillmentStatusId: FulfillmentStatus.InLavorazione,
    }).finally(() => {
      setSelectedOrder([]);
      getAllOrders();
    });
  };

  const onPrintClick = async () => {
    if (selectedOrderToPrint.length > 0) {
      let trackingNumbers = selectedOrderToPrint.map(
        (orderToPrint) => orderToPrint.tracking_number
      );
      setSelectedOrderToPrint([]);
      await printWaybillShipments({ trackingNumbers });
    }
    setPrintingMode(!printingMode);
  };

  const handleCancelPrintOrder = async () => {
    setSelectedOrderToPrint([]);
    setPrintingMode(false);
  };

  const handleFileUpload = async (event) => {
    setOrdersXLSX([]);
    const file = event.target.files[0];
    if (
      file &&
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const workbook = XLSX.read(new Uint8Array(arrayBuffer), {
          type: "array",
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        var jsonData = XLSX.utils.sheet_to_json(worksheet);
        jsonData = jsonData.map(mapProperties);
        if ((jsonData?.length ?? 0) > 0) {
          setOrdersXLSX(jsonData);
        }
      };
      reader.readAsArrayBuffer(file);
    }
    setErrorImportXLSX(null);
  };

  const onConfirmImportXLSX = () => {
    if (customerXLSX == undefined) {
      setErrorImportXLSX("Inserisci prima un cliente.");
      return;
    }
    insertAllByXlsx({
      orders: ordersXLSX,
      registeredUserId: customerXLSX?.value?.id ?? 0,
    }).then((res) => {
      if (res.error) {
        setMessageNotification(
          res?.error?.data?.message ?? "Caricamento fallito"
        );
        setSuccessNotification(false);
        setShowNotification(true);
        return;
      }
      setMessageNotification("Caricamento concluso con successo");
      setSuccessNotification(true);
      setShowNotification(true);
      setShowImportXLSXPopup(false);
      getAllOrders();
    });
  };

  let content;
  if (isLoading) {
    content = <LoadingIcon color={"text-gray-900"} />;
  } else if (isSuccess) {
    //|| isLoading) {
    content = (
      <>
        <NavShopifyOrders
          numOrders={ordersLength}
          page={page}
          rowsPage={pageRows}
          setPage={setPage}
          setRowsPage={setPageRows}
        />
        <ListShopifyOrder
          getAllOrders={getAllOrders}
          showOrderProducts={showOrderProducts}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          error={error}
          orders={
            printingMode
              ? shopifyOrders?.filter((so) => so.tracking_number)
              : shopifyOrders
          }
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          ordersToPay={ordersToPay}
          setOrdersToPay={setOrdersToPay}
          selectedOrderToPrint={selectedOrderToPrint}
          printingMode={printingMode}
          handleAddItem={handleAddItem}
          handleItemToPrint={handleItemToPrint}
        />
      </>
    );
  } else if (isError) {
    setErrorMessage(error.data);
  }

  const printPdf = (base64Pdf) => {
    const linkSource = `data:application/pdf;base64,${base64Pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `LETTEREDIVETTURA.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const [showPopupSelectStore, setShowPopupSelectStore] = useState(false);

  useEffect(() => {
    if (printSuccess) printPdf(base64Pdf);
    if (isPrintError) console.log("errore stampa: ", printError);
  }, [printLoading]);

  useEffect(() => {
    if (errorUpdateFulfillmentEvent) {
      setErrorMessage(errorUpdateFulfillmentText.data.message);
      setShowErrorMessage(true);
    }
    if (successUpdateFulfillment) {
      setShowSuccessUpdateFulfillment(true);
    }
  }, [loadingUpdateFulfillment]);

  return (
    <main className="px-4">
      <SimpleNotification
        message={dataUpdateFulfillment?.message ?? ""}
        show={showSuccessUpdateFulfillment}
        setShow={setShowSuccessUpdateFulfillment}
      />

      <SimpleNotification
        message={messageNotification}
        show={showNotification}
        setShow={setShowNotification}
        success={successNotification}
      />

      <div className="flex flex-wrap gap-2 items-end">
        <div className="flex gap-2 mt-2 flex-wrap items-end">
          <div>
            <DropDown
              label={"Contrassegno"}
              items={options}
              selectedItem={filterCashOnDelivery}
              onChange={setFilterCashOnDelivery}
            />
          </div>
          <div>
            <DropDown
              label={"Pagati"}
              items={options}
              selectedItem={filterPaidOrder}
              onChange={setFilterPaidOrder}
            />
          </div>
          <div>
            <DropDown
              label={"Spedito"}
              items={options}
              selectedItem={filterShippingSent}
              onChange={setFilterShippingSent}
            />
          </div>
          {auth.roles.includes(1) ? (
            <div>
              <DropDown
                label={"Stampati"}
                items={options}
                selectedItem={filterIsPrinted}
                onChange={setFilterIsPrinted}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="flex-col ">
            <span className="block text-sm font-medium leading-6 text-gray-900">
              Negozio
            </span>
            {isSuccessStores && (
              <Select
                placeholder={"Cerca Negozio..."}
                isSearchable={true}
                options={stores}
                onChange={setFilterStore}
                isClearable={true}
              />
            )}
          </div>
          {auth.roles.includes(1) ? (
            <div className="flex-col">
              <span className="block text-sm font-medium leading-6 text-gray-900">
                Cliente
              </span>
              <Select
                placeholder={"Cerca Cliente..."}
                isSearchable={true}
                options={customers}
                onChange={setFilterCustomer}
                isClearable={true}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="flex gap-2 items-center">
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Data Inizio Ordine
              </label>
              <input
                className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                type="date"
                value={filterStartDate}
                onChange={(e) => setFilterStartDate(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Data Fine Ordine
              </label>
              <input
                className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                type="date"
                value={filterEndDate}
                onChange={(e) => setFilterEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 flex-1">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") getAllOrders();
            }}
            type="text"
            name="name"
            id="name"
            className="block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            placeholder="Cerca in ordini"
          />
          <button
            onClick={(e) => getAllOrders()}
            type="button"
            className="rounded-xl bg-green-600 p-2 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600
           bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm  text-center "
          >
            <SearchIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="flex justify-between mt-3 flex-wrap gap-2 ">
        <div className="flex gap-2 flex-wrap items-center">
          <CreateLdvButton
            isLoading={false}
            onClick={() => onClick()}
            textButton={"Richiedi Evasione"}
          />
          <PopupSelectStore
            show={showPopupSelectStore}
            setShow={setShowPopupSelectStore}
          />
          <button
            onClick={(e) => setShowPopupSelectStore(true)}
            type="button"
            className={
              "bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            }
          >
            Aggiorna Spedizioni
          </button>
          <PopupTermsAndConditions
            open={openTermsAndConditions}
            setOpen={setOpenTermsAndConditions}
          >
            <div className="max-h-96 max-w-96" id="container-popup-policy">
              <TermsAndConditionsText />
            </div>
          </PopupTermsAndConditions>

          {auth.roles.find((role) => role !== 1) ? (
            <button
              onClick={(e) => importAllOrder().finally(() => getAllOrders())}
              type="button"
              className={
                "bg-gradient-to-r bg-gray-200 text-gray-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none inline-flex gap-2 items-center px-5 py-2.5 border border-transparent text-base  font-medium rounded-md shadow-sm  focus:ring-offset-2 "
              }
            >
              {loadingImport ? (
                <LoadingIcon color={"text-gray-700"} />
              ) : (
                <DownloadIcon className="h-5 w-5" />
              )}
              Importa Ordini
            </button>
          ) : (
            <></>
          )}
          {auth.roles.find((role) => role == 2) && (
            <>
              <PopupShippingPriceList
                open={showShippingPriceList}
                setOpen={setShowShippingPriceList}
              />
              <span
                className="text-green-600 underline cursor-pointer"
                onClick={() => setShowShippingPriceList(true)}
              >
                Mostra Listino Spedizione
              </span>
            </>
          )}
          {auth.roles.find((role) => role == 1) ? (
            <>
              <button
                onClick={() => setShowImportXLSXPopup(true)}
                type="button"
                className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center  inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              >
                Carica Ordini Tramite XLSX
                <DocumentIcon className="h-5 w-5" />
              </button>
              <PopupDialog
                open={showImportXLSXPopup}
                setOpen={setShowImportXLSXPopup}
              >
                <div className="flex-col">
                  <span className="block text-sm font-medium leading-6 text-gray-900">
                    Cliente
                  </span>
                  <Select
                    placeholder={"Cerca Cliente..."}
                    isSearchable={true}
                    options={customers}
                    onChange={setCustomerXLSX}
                    isClearable={true}
                  />
                </div>
                <div className="mt-2 ">
                  <ButtonFileCustom
                    acceptedExt={".xlsx"}
                    textButton={"Importa da xlsx"}
                    CustomIcon={UploadIcon}
                    isLoading={loadingUpdateFulfillment}
                    onClick={handleFileUpload}
                  />
                </div>

                <BadgeErrorMessage
                  isError={errorImportXLSX != null}
                  message={errorImportXLSX}
                />
                <div className="w-full flex justify-end mt-2">
                  <button
                    onClick={() => onConfirmImportXLSX()}
                    type="button"
                    className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center  inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                  >
                    Conferma
                    <CheckIcon className="h-5 w-5" />
                  </button>
                </div>
              </PopupDialog>
            </>
          ) : (
            <></>
          )}
        </div>
        {/* TODO da decommentare per il pagamento massivo dei prodotti */}
        {/* {auth.roles.find((role) => role == 2) ? (
          <button
            onClick={() => {}}
            type="button"
            className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
          >
            <CreditCardIcon className="h-4 w-4" />
            Paga tutto ({ordersToPay.length})
             {isLoading ? (
        <LoadingIcon size={"h-4 w-4"} color={"text-white"} />
      ) : Icon == undefined ? (
        <></>
      ) : (
        <Icon className="h-4 w-4" />
      )} 
          </button>
        ) : (
          <></>
        )} */}
        {auth.roles.find((role) => role == 1) ? (
          <div className="flex gap-3 items-center">
            {printingMode ? (
              <a
                className="cursor-pointer underline underline-offset-8"
                onClick={() => handleSelectAllItemToPrint()}
              >
                Seleziona Tutto
              </a>
            ) : (
              <></>
            )}
            {(shopifyOrders?.length ?? 0) > 0 ? (
              <PrintPdfButton
                backgroundClass={
                  "bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800"
                }
                isLoading={printLoading}
                onClick={() => onPrintClick()}
                textButton={`STAMPA ${
                  printingMode ? `(${selectedOrderToPrint.length})` : ""
                }`}
                Icon={PrinterIcon}
              />
            ) : (
              <></>
            )}

            {printingMode ? (
              <SimpleButton
                isLoading={printLoading}
                onClick={() => handleCancelPrintOrder()}
                textButton={"ANNULLA"}
                Icon={XIcon}
                backgroundClass={
                  "bg-gray-200 hover:bg-gray-300 focus:ring-gray-200"
                }
                colorClass={
                  "text-gray-400 hover:text-gray-500 focus:ring-gray-400"
                }
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="mt-2 flex flex-wrap gap-2">
        <ToggleButton
          enabled={showOrderProducts}
          onChangeEvent={(e) => {
            setShowOrderProducts(e);
          }}
          text={"Mostra Prodotti nell'ordine"}
        />
        {auth.roles.includes(1) && (
          <ToggleButton
            enabled={showFailedShipments}
            onChangeEvent={(e) => {
              setShowFailedShipments(e);
            }}
            text={`Mostra Spedizioni Fallite ${
              showFailedShipments ? `(${shopifyOrders?.length ?? 0})` : ""
            }`}
          />
        )}
      </div>
      <div>{content}</div>
      <ErrorBanner
        message={errorMessage}
        show={showErrorMessage}
        setShow={setShowErrorMessage}
      />
      {/* <CartMenuButton /> */}
      {/* <BadgeErrorOrders />
       */}
    </main>
  );
};

const mapProperties = (obj) => {
  return {
    fullName: obj["nome completo"] || obj["NOME COMPLETO"] || "",
    shippingAddress:
      obj["indirizzo di spedizione"] || obj["INDIRIZZO DI SPEDIZIONE"] || "",
    shippingZip: obj["codice postale"] || obj["CODICE POSTALE"] || "",
    shippingProvince: obj["provincia"] || obj["PROVINCIA"] || "",
    city: obj["citta"] || obj["CITTA"] || "",
    shippingPhone: obj["telefono"] || obj["TELEFONO"] || "",
    email: "",
    customerPhone: obj["telefono"] || obj["TELEFONO"] || "",
    note: obj["note"] || obj["NOTE"] || "",
    products: [
      { sku: obj["sku_prodotto_1"], qta: obj["qta_1"] },
      { sku: obj["sku_prodotto_2"], qta: obj["qta_2"] },
      { sku: obj["sku_prodotto_3"], qta: obj["qta_3"] },
      { sku: obj["sku_prodotto_4"], qta: obj["qta_4"] },
    ].filter((p) => p.sku != null),
    price: obj["COD"],
  };
};
