import { useEffect, useState } from "react";
import { useGetProductsCatalogQuery, useLazyGetProductsCatalogQuery } from "../services/catalogApi";
import { LoadingIcon } from "../components/custom/LoadingIcon";
import CatalogProductsList from "../components/catalog/CatalogProductsList";
import SearchBar from "../components/catalog/SearchBar";
import { PlusIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CartMenuButton } from "../components/custom/CartMenuButton";

export const CatalogPage = () => {

  const [searchText, setSearchText] = useState("%20")
  var auth = useSelector((state) => state.auth);

  const { data: products, isLoading, isSuccess, isError, error, isUninitialized } = useGetProductsCatalogQuery(searchText, {
    refetchOnMountOrArgChange: true
  });

  return (
    <main className="px-4">
      <SearchBar
        placeholder={"Cerca per nome o sku..."}
        onChange={(search) => {
          if (search == "") {
            setSearchText("%20")
          }
          if (search.length <= 3) return;          
          setSearchText(search);
        }}
      />
      {isLoading ? (
        <LoadingIcon color={"text-gray-900"} />
      ) : (
        <CatalogProductsList products={products} />
      )}
      {auth.roles.includes(1) ? (
        <Link
          to={{
            pathname: "/product",
            state: undefined,
          }}
          className="z-50 fixed bottom-3 right-3 bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
        >
          Aggiungi Prodotto
          <PlusIcon className="h-4 w-4" />
        </Link>
      ) : (
        <></>
      )}
      {auth.roles.find((r) => r == 2) && <CartMenuButton />}
    </main>
  );
};
