import { userApi } from "./userApi";

const shippingPriceListApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getShippingPriceList: builder.query({
      query: () => `shipping-price-list/get`,
      providesTags: ["ShippingPriceList"],
    }),
    getUserShippingPriceList: builder.query({
      query: () => `shipping-price-list/get-by-user`,
    }),
    addShippingPriceList: builder.mutation({
      query: (shippingPriceListNew) => ({
        url: `shipping-price-list/add`,
        method: "POST",
        body: shippingPriceListNew,
      }),
      invalidatesTags: [
        "ShopifyOrders",
        "Shipments",
        "ProductsPriceList",
        "ShippingPriceList",
        "ShippingPriceListWeightRange",
      ],
    }),

    editShippingPriceList: builder.mutation({
      query: (shippingPriceListEdited) => ({
        url: `shipping-price-list/edit`,
        method: "PUT",
        body: shippingPriceListEdited,
      }),
      invalidatesTags: [
        "ShopifyOrders",
        "Shipments",
        "ProductsPriceList",
        "ShippingPriceList",
        "ShippingPriceListWeightRange",
      ],
    }),

    deleteShippingPriceList: builder.mutation({
      query: (id) => ({
        url: `shipping-price-list/delete/${id}`,
        method: "delete",
      }),
      invalidatesTags: [
        "ShopifyOrders",
        "Shipments",
        "ProductsPriceList",
        "ShippingPriceList",
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetShippingPriceListQuery,
  useAddShippingPriceListMutation,
  useEditShippingPriceListMutation,
  useDeleteShippingPriceListMutation,
  useGetUserShippingPriceListQuery,
  useLazyGetUserShippingPriceListQuery
} = shippingPriceListApi;
