import { useEffect, useState } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useAddProductCatalogImageMutation,
  useAddProductCatalogMutation,
  useDeleteProductCatalogImageMutation,
  useEditProductCatalogMutation,
  useGetOneProductByIdQuery,
  useGetProductCatalogByIdMutation,
  useMoveProductCatalogImageMutation,
  useUploadFileFetchMutation,
} from "../services/catalogApi";
import { TrashIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { imagesUrl } from "../config/baseUrl";
import SimpleNotification from "../components/SimpleNotification";
import { Checkbox } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0;
};

export default function ManageProductCatalogPage({}) {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [mutationResponse, setMutationResponse] = useState({
    message: "",
    show: false,
    success: false,
  });

  const [
    getProductCatalogById,
    {
      data: prodCatalog,
      isLoading: gettingProduct,
      isSuccess: gettedProduct,
      isError: errorGettingProductEvent,
      error: errorGettingProductText,
    },
  ] = useGetProductCatalogByIdMutation();

  const [productState, setProductState] = useState({});
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    if (productId) getProductCatalogById(productId);
  }, []);

  useEffect(() => {
    if (gettedProduct) {
      setProductState(prodCatalog);
    }
  }, [gettingProduct]);

  const [
    uploadFileFetch,
    { data: fileFetch, isLoading, isSuccess, isError, error },
  ] = useUploadFileFetchMutation();

  async function handleChange(e) {
    let product = {};
    var fileList = Array.from(e.target.files);
    var fileAdded = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      var formData = new FormData();
      formData.append("files", file);
      let resUpload = await uploadFileFetch(formData).unwrap();
      let fileUploaded = resUpload?.file;
      if (!fileUploaded) {
        setMutationResponse({
          message: `E' stato impossibile caricare il file ${file.name}`,
          show: true,
          success: false,
        });
        return;
      }
      setMutationResponse({
        message: `Foto ${file.name} caricata correttamente`,
        show: true,
        success: true,
      });
      let productCatalogImage = {
        src: fileUploaded.filename,
        productCatalogId: productId,
        productCatalogImageId: 0,
      };
      if (productId) {
        productCatalogImage = await addProductCatalogImage(
          productCatalogImage
        ).unwrap();
        fileAdded.push(productCatalogImage[0]);
      } else {
        fileAdded.push(productCatalogImage);
      }
    }
    if (isObjectEmpty(productState)) {
      product = {
        images: fileAdded,
      };
    } else {
      product = {
        ...productState,
        images:
          productState?.images == null
            ? fileAdded
            : [...productState.images, ...fileAdded],
      };
    }
    setProductState(product);
    //if ((fileList?.length ?? 0) > 4) window.location.reload();
  }

  const [
    addProductCatalog,
    {
      data: productAdded,
      isLoading: adding,
      isError: eventErrorAdding,
      isSuccess: added,
      error: textErrorAdding,
    },
  ] = useAddProductCatalogMutation();

  const [
    addProductCatalogImage,
    {
      isLoading: addingImage,
      isError: eventErrorAddingImage,
      isSuccess: addedImage,
      error: textErrorAddingImage,
    },
  ] = useAddProductCatalogImageMutation();

  const [
    deleteProductCatalogImage,
    {
      isLoading: deletingImage,
      isError: eventErrorDeletingImage,
      isSuccess: deletedImage,
      error: textErrorDeletingImage,
    },
  ] = useDeleteProductCatalogImageMutation();

  const [
    moveProductCatalogImage,
    {
      isLoading: movingImage,
      isError: eventErrorMovingImage,
      isSuccess: movedImage,
      error: textErrorMovngImage,
    },
  ] = useMoveProductCatalogImageMutation();

  const [
    editProductCatalog,
    {
      isLoading: editing,
      isError: eventErrorEditing,
      isSuccess: edited,
      error: textErrorEditing,
    },
  ] = useEditProductCatalogMutation();

  const handleAddClick = async (product, e) => {
    e.preventDefault();
    await addProductCatalog(product); //.finally(() => window.location.reload());
  };

  const handleEditClick = async (product, e) => {
    e.preventDefault();
    await editProductCatalog(product);
  };

  useEffect(() => {
    if (eventErrorEditing) {
      setMutationResponse({
        message: textErrorEditing?.data?.message ?? "Errore generico",
        show: true,
        success: false,
      });
    }
    if (edited) {
      setMutationResponse({
        message: "Prodotto Modificato Correttamente",
        show: true,
        success: true,
      });
    }
  }, [editing]);

  useEffect(() => {
    if (eventErrorAdding) {
      setMutationResponse({
        message: textErrorAdding?.data?.message ?? "Errore generico",
        show: true,
        success: false,
      });
    }
    if (added) {
      setMutationResponse({
        message: "Prodotto Aggiunto Con Successo",
        show: true,
        success: true,
      });

      navigate(`/product/${productAdded.productCatalogId}`);
    }
  }, [adding]);
  //step 1 o -1
  const onClickMoveImage = async (step, image) => {
    let prodImages = productState.images.map((i, index) => ({
      ...i,
      numeration: index + 1,
    }));
    image = prodImages.find((i) => i.src == image.src);
    let newPosition = image.numeration + step;
    if (newPosition <= 0) return;
    if (newPosition > prodImages.length) return;
    let newProdImages = prodImages.map((i) => {
      if (i.numeration == newPosition) {
        i.numeration = newPosition - step;
        return i;
      }
      if (i.src == image.src) {
        i.numeration = newPosition;
        return i;
      }
      return i;
    });
    newProdImages = newProdImages.sort((a, b) => a.numeration - b.numeration);
    setProductState({ ...productState, images: newProdImages });
    if (productId)
      await moveProductCatalogImage({ productCatalogImages: newProdImages });
    setSelectedImageIndex(selectedImageIndex + step);
  };

  return (
    <div className="bg-white">
      <SimpleNotification
        show={mutationResponse.show}
        setShow={() =>
          setMutationResponse({
            ...mutationResponse,
            show: !mutationResponse.show,
          })
        }
        message={mutationResponse.message}
        success={mutationResponse.success}
      />
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          {/* Image gallery */}
          <TabGroup
            className="flex flex-col-reverse"
            selectedIndex={selectedImageIndex}
            onChange={setSelectedImageIndex}
          >
            {/* Image selector */}
            <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none relative">
              <TabList className="grid grid-cols-4 gap-6">
                {productState?.images?.map((image, index) => (
                  <Tab
                    key={index}
                    className="relative h-24 cursor-pointer rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                  >
                    {({ selected }) => (
                      <>
                        <span className="sr-only">{image.name}</span>
                        <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img
                            src={`${imagesUrl}${image.src}`}
                            alt={image.name}
                            className="h-full w-full object-cover object-center"
                          />
                        </span>
                        <div className="flex absolute bottom-1 left-1">
                          <div
                            className="bg-black rounded-l-md cursor-pointer"
                            onClick={() => onClickMoveImage(-1, image)}
                          >
                            <ArrowLeftIcon className="h-4 w-4 text-white m-1 " />
                          </div>
                          <div
                            className="bg-black rounded-r-md cursor-pointer"
                            onClick={() => onClickMoveImage(1, image)}
                          >
                            <ArrowRightIcon className="h-4 w-4 text-white m-1 " />
                          </div>
                        </div>
                        <Link
                          className="bg-red-900 rounded-md shadow flex justify-center items-center w-8 h-8 cursor-pointer z-50 absolute bottom-1 right-1"
                          onClick={async () => {
                            setProductState({
                              ...productState,
                              images: productState.images.filter(
                                (im) => im.src !== image.src
                              ),
                            });
                            if (productId)
                              await deleteProductCatalogImage(
                                image.productCatalogImageId
                              );
                          }}
                        >
                          <TrashIcon className="h-6 w-6 text-white" />
                        </Link>
                        <span
                          className={classNames(
                            selected ? "ring-green-500" : "ring-transparent",
                            "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
                ))}
                <Tab
                  key={"add-image"}
                  className="relative h-24 cursor-pointer  rounded-md bg-gray-100 text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                >
                  <span className="absolute inset-0 overflow-hidden rounded-md flex items-center justify-center">
                    <label
                      htmlFor="fileInput"
                      className="relative cursor-pointer "
                    >
                      <span>Aggiungi Immagini</span>
                      <input
                        id="fileInput"
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handleChange}
                        className="sr-only"
                      />
                    </label>
                  </span>
                </Tab>
              </TabList>
            </div>
            <TabPanels className="aspect-h-1 aspect-w-1 max-w-128">
              {productState?.images?.map((image, index) => (
                <TabPanel key={index}>
                  <img
                    src={`${imagesUrl}${image.src}`}
                    alt={image.alt}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                  />
                </TabPanel>
              ))}
            </TabPanels>{" "}
          </TabGroup>

          <form
            className="mt-6"
            onSubmit={(e) =>
              (productState?.productCatalogId ?? 0) == 0
                ? handleAddClick(productState, e)
                : handleEditClick(productState, e)
            }
          >
            <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0 flex flex-col gap-2">
              <div className="flex flex-col">
                <label htmlFor="prodotto">Prodotto</label>
                <input
                  className={`w-full  border-2 border-gray-400 py-2 pl-2 rounded-md`}
                  type={"text"}
                  placeholder={"Nome Prodotto"}
                  name={"prodotto"}
                  value={productState?.name ?? ""}
                  onChange={(e) =>
                    setProductState({ ...productState, name: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="sku">SKU</label>
                <input
                  className={`w-full  border-2 border-gray-400 py-2 pl-2 rounded-md`}
                  type={"text"}
                  name={"sku"}
                  placeholder={"SKU"}
                  value={productState?.sku ?? ""}
                  onChange={(e) =>
                    setProductState({ ...productState, sku: e.target.value })
                  }
                />
              </div>
              <div className="md:flex-nowrap flex flex-wrap gap-2">
                <div className="flex flex-col w-full">
                  <label htmlFor="prezzo-cliente">€ Cliente</label>
                  <input
                    className={`w-full border-2 border-gray-400 py-2 pl-2 rounded-md`}
                    type={"number"}
                    placeholder={"prezzo-cliente"}
                    name={"Prezzo Cliente"}
                    value={productState?.price ?? ""}
                    onChange={(e) =>
                      setProductState({
                        ...productState,
                        price: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="prezzo-fornitore">€ Fornitore</label>
                  <input
                    className={`w-full  border-2 border-gray-400 py-2 pl-2 rounded-md `}
                    type={"number"}
                    name="prezzo-fornitore"
                    placeholder={"Prezzo Fornitore"}
                    value={productState?.adminPrice ?? ""}
                    onChange={(e) =>
                      setProductState({
                        ...productState,
                        adminPrice: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="name">Kg</label>
                  <input
                    className={`w-full  border-2 border-gray-400 py-2 pl-2 rounded-md`}
                    type={"number"}
                    placeholder={"Peso"}
                    name={"Peso"}
                    value={productState?.productWeight ?? ""}
                    onChange={(e) =>
                      setProductState({
                        ...productState,
                        productWeight: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="pz-magazzino">Pz Magazzino</label>
                  <input
                    className={`w-full  border-2 border-gray-400 py-2 pl-2 rounded-md`}
                    type={"text"}
                    name={"pz-magazzino"}
                    placeholder={"Q.tà magazzino"}
                    value={productState?.stockQuantity ?? ""}
                    onChange={(e) =>
                      setProductState({
                        ...productState,
                        stockQuantity: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label htmlFor="descrizione">Descrizione</label>
                <textarea
                  className={`w-full  border-2 border-gray-400 py-2 pl-2 rounded-md min-h-72`}
                  placeholder={"Descrizione"}
                  name={"descrizione"}
                  value={productState?.description ?? ""}
                  onChange={(e) =>
                    setProductState({
                      ...productState,
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex gap-2 items-center bg-gray-300 rounded-md mt-4 w-fit pr-4">
                <Checkbox
                  checked={productState?.visible ?? false}
                  onChange={(e) =>
                    setProductState({
                      ...productState,
                      visible: e.target.checked,
                    })
                  }
                />
                <span className="font-bold text-gray-700">Visibile</span>
              </div>
              <div className="mt-10">
                <button
                  disabled={adding || editing}
                  type="submit"
                  className="flex w-full flex-1 items-center justify-center rounded-md border border-transparent bg-green-600 px-8 py-3 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                >
                  {(productState?.productCatalogId ?? 0) == 0
                    ? "Aggiungi"
                    : "Salva"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
