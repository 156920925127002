import React from "react";
import { useGetAllCustomerQuery } from "../services/customerApi";
import { LoadingIcon } from "./custom/LoadingIcon";
import Select from "react-select";

function DropDownCustomer({ onChange }) {
  var { customers, isLoading, isSuccess, isError, error } =
    useGetAllCustomerQuery("%20", {
      selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
        var customers = data?.map((cus) => ({
          value: cus,
          label: `${cus?.name} ${cus?.surname}`,
        }));
        return {
          customers,
          isLoading,
          isSuccess,
          isError,
          error,
        };
      },
    });
  return isLoading ? (
    <LoadingIcon size={"h-6 w-6"} color={"text-black"} />
  ) : isSuccess ? (
    <div className="flex-col">
      <span className="block text-sm font-medium leading-6 text-gray-900">
        Cliente
      </span>
      <Select
        placeholder={"Cerca Cliente..."}
        isSearchable={true}
        options={customers}
        onChange={(value) => onChange(value)}
        isClearable={true}
      />
    </div>
  ) : (
    <></>
  );
}

export default DropDownCustomer;
