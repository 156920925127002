import React, { useEffect, useState } from 'react'
import ListTable from './custom/ListTable'
import HeaderTable from './custom/HeaderTable'
import RowTable from './custom/RowTable'
import HeaderColTable from './custom/HeaderColTable'
import HiddenHeaderColTable from './custom/HiddenHeaderColTable'
import BodyTable from './custom/BodyTable'
import CellRowTable from './custom/CellRowTable'
import { useDeleteRechargeBalanceMutation, useGetAllRechargeBalancesQuery, useUpdateRechargeBalanceMutation } from '../services/rechargeBalanceApi'
import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import ButtonTable from './custom/ButtonTable'
import moment from 'moment'
import PopupConfirmation from './PopupConfirmation'
import SimpleNotification from './SimpleNotification'
import { InputField } from './RegistrationForm'
import { SaveOutlined } from '@mui/icons-material'

function ListRecharges({ userId }) {
    const [ selectedRecharge, setSelectedRecharge ] = useState({})
    const [ showConfirmationMessage, setShowConfirmationMessage ] = useState(false)
    const [ editMode, setEditMode ] = useState(false)
    const [ mutationResponse, setMutationResponse ] = useState({
        message: "",
        show: false,
        success: false        
    })
    const { data:recharges, isLoading, isSuccess, isError, error } = useGetAllRechargeBalancesQuery(userId, {
        skip: userId == undefined,
        refetchOnMountOrArgChange: true
    })
    const [ deleteRechargeBalance, { isLoading: deletingRecharge, isSuccess: deletedRecharge, isError: isErrorDeletingRecharge, error: errorDeletingRecharge }] = useDeleteRechargeBalanceMutation()

    const [ updateRechargeBalance, { isLoading: updatingRecharge, isSuccess: updatedRecharge, isError: isErrorUpdatingRecharge, error: errorUpdatingRecharge }] = useUpdateRechargeBalanceMutation()

    const onClickDeleteRecharge = (recharge) => {
        setSelectedRecharge(recharge)
        setShowConfirmationMessage(!showConfirmationMessage)
    }

    const onClickEditRecharge = (recharge) => {
        setSelectedRecharge(recharge)
        setEditMode(true)        
    }

    useEffect(() => {
        if(deletedRecharge){
            setMutationResponse({
                message: "Ricarica Cancellata Correttamente.",
                show: true,
                success: true
            })
        }
        if(isErrorDeletingRecharge){
            setMutationResponse({
                message: errorDeletingRecharge?.data?.message ?? "Errore generico",
                show: true,
                success:false
            })      
        }
        setShowConfirmationMessage(false)
        setSelectedRecharge({})
      }, [deletingRecharge])

      useEffect(() => {
        if(updatedRecharge){
          setMutationResponse({
            message: "Ricarica Modificata Correttamente.",
            show: true,
            success: true
          })
        }
        if(isErrorUpdatingRecharge){

          setMutationResponse({
            message: errorDeletingRecharge?.data?.message ?? "Errore generico",
            show: true,
            success:false
          })      
        }
        setEditMode(false)
        setSelectedRecharge({})
      }, [updatingRecharge])      

  return (
    <section id='list-recharges'>
        <SimpleNotification 
            message={mutationResponse.message} 
            success={mutationResponse.success}
            show={mutationResponse.show} 
            setShow={() => setMutationResponse({ ...mutationResponse, show: !mutationResponse.show })} 
        />
        <PopupConfirmation 
            show={showConfirmationMessage} 
            setShow={setShowConfirmationMessage} 
            title={"Attenzione"} 
            handleConfirmClick={() => deleteRechargeBalance(selectedRecharge.id)}
            message={`Sei sicuro di voler cancellare la ricarica di ${selectedRecharge?.amount}€ del ${moment(selectedRecharge?.createdDate).format("DD-MM-YYYY HH:mm")}?`}
        />
        <ListTable className="mt-2">
            <HeaderTable>
            <RowTable>
                <HeaderColTable>Importo</HeaderColTable>
                <HeaderColTable>Data</HeaderColTable>
                <HiddenHeaderColTable />
            </RowTable>
            </HeaderTable>
            <BodyTable>
            {recharges?.map((recharge, index) => (
                <RowTable key={index}>
                <CellRowTable>
                    {editMode == true && selectedRecharge.id == recharge.id ? 
                        <InputField value={selectedRecharge.amount} onChange={(e) => setSelectedRecharge({ ...selectedRecharge, amount: e.target.value })} type='number' />
                     : `${recharge.amount}€`}
                    

                </CellRowTable>
                <CellRowTable>{moment(recharge.createdDate).format("DD-MM-YYYY HH:mm") }</CellRowTable>
                {editMode == true && selectedRecharge.id == recharge.id ? 
                <CellRowTable>
                        <ButtonTable    
                            Icon={SaveOutlined}
                            color={
                            "from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800"
                            }
                            onClick={() => updateRechargeBalance({ ...selectedRecharge })}
                        />
                </CellRowTable>
                :
                <CellRowTable>
                    <div className="flex gap-2">
                        <ButtonTable
                            Icon={PencilIcon}
                            color={
                            "from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800"
                            }
                            onClick={() => onClickEditRecharge(recharge)}
                        />
                        <ButtonTable
                            Icon={TrashIcon}
                            color={
                            "from-red-400 via-red-500 to-red-600 focus:ring-red-300 dark:focus:ring-red-800"
                            }
                            onClick={() => onClickDeleteRecharge(recharge)}
                        />
                    </div>
                </CellRowTable>
                }
                </RowTable>
            ))}
            </BodyTable>
        </ListTable>
      </section>
  )
}

export default ListRecharges