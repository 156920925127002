import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  CalendarIcon,
  CheckCircleIcon,
  CurrencyEuroIcon,
  PencilAltIcon,
  TrashIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { MyDateTime } from "../functions/date_time";
import { useSelector } from "react-redux";
import {
  useAddOrderProductMutation,
  useDeleteOrderProductMutation,
  useGetOneOrderQuery,
  useLazyGetOneOrderQuery,
  usePostOneOrderMutation,
  useUpdateFulfillmentRequestMutation,
  useUpdateOrderProductMutation,
  useUpdateOrderShipmentMutation,
  useUpdateSkuOrderProductMutation,
} from "../services/orderApi";
import { LoadingIcon } from "../components/custom/LoadingIcon";
import BannerMessage from "../components/order/BannerMessage";
import { useCreateLDVMutation } from "../services/spedisciOnlineApi";
import { CreateLdvButton } from "../components/CreateLdvButton";
import SimpleNotification from "../components/SimpleNotification";
import { imagesUrl } from "../config/baseUrl";
import { TrackingButton } from "../components/order/TrackingButton";
import InputToggleEdit from "../components/catalog/InputToggleEdit";
import { FulfillmentStatus, FulfillmentStatusRadioButtons } from "../enums/FulfillmentStatus";
import BadgeComponent from "../components/custom/BadgeComponent";
import { Tooltip } from "@mui/material";
import { PlusIcon } from "@heroicons/react/solid";
import PopupDialog from "../components/custom/PopupDialog";
import { useGetProductsCatalogQuery } from "../services/catalogApi";
import SearchBar from "../components/catalog/SearchBar";
import CatalogProductsList from "../components/catalog/CatalogProductsList";
import CatalogProductBox from "../components/catalog/CatalogProductBox";
import ModalConfirmationRequest from "../components/custom/ModalConfirmationRequest";

function ShopifyOrderDetailPage() {
  const { orderId } = useParams();
  const [
    getOneOrder,
    { data: order, isLoading, isSuccess, isError, error, isUninitialized },
  ] = usePostOneOrderMutation() //useLazyGetOneOrderQuery();
  const [mutationResponse, setMutationResponse] = useState({
    message: "",
    show: false,
    success: false
  })
  const [confirmationRequest, setConfirmationRequest] = useState({
    message: "",
    show: false,
  })
  const [orderProductToDelete, setOrderProductToDelete] = useState({});
  const [showProductCatalog, setShowProductCatalog] = useState(false);
  const [searchText, setSearchText] = useState("%20")
  const [updatedShipment, setUpdatedShipment] = useState({});
  const [orderProducts, setOrderProducts] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [
    updateSkuOrderProduct,
    {
      isLoading: updatingSku,
      isSuccess: isSuccessUpdatingSku,
      isError: isErrorUpdatingSku,
      error: errorUpdatingSku
    },
  ] = useUpdateSkuOrderProductMutation();

  const [
    updateOrderProduct,
    {
      isLoading: updatingOrderProduct,
      isSuccess: isSuccessUpdatingOrderProduct,
      isError: isErrorUpdatingOrderProduct,
      error: errorUpdatingOrderProduct
    },
  ] = useUpdateOrderProductMutation();

  const [
    updateOrderShipment,
    {
      isLoading: updatingOrderShipment,
      isSuccess: isSuccessUpdating,
      isError: isErrorUpdating,
      error: errorUpdating
    },
  ] = useUpdateOrderShipmentMutation();

  const [
    addOrderProduct,
    {
      isLoading: addingOrderProduct,
      isSuccess: isSuccessAddOrderProduct,
      isError: isErrorAddOrderProduct,
      error: errorAddOrderProduct
    },
  ] = useAddOrderProductMutation();

  const [
    deleteOrderProduct,
    {
      isLoading: deleteingOrderProduct,
      isSuccess: isSuccessDeleteOrderProduct,
      isError: isErrorDeleteOrderProduct,
      error: errorDeleteOrderProduct
    },
  ] = useDeleteOrderProductMutation();

  const [
    createLDV,
    {
      isLoading: createLoading,
      isSuccess: createSuccesss,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateLDVMutation();

  const handleEditClick = async (order) => {
    await updateOrderShipment({
      orderId: order.id,
      updatedOrderShipment: updatedShipment,
    });
  };

  const onConfirmDeleteOrderProduct = async () => {
    await deleteOrderProduct({ orderId, productOrderId: orderProductToDelete.id})
    setConfirmationRequest({ ...confirmationRequest, show: !confirmationRequest.show })
    setOrderProductToDelete({})
    getOneOrder(orderId)
  };

  const onAddProductClick = async (productCatalog) => {
    let orderProduct =  await addOrderProduct({ orderId, productCatalog })
    setShowProductCatalog(false)
    getOneOrder(orderId)
  };

  const handleEditQtyProduct = async (orderProductId) => {
    let orderProduct = orderProducts.find((op) => op.id == orderProductId)
    if(!orderProduct) return;
    await updateOrderProduct({
      orderProduct
    });
  };

  const handleEditSkuProduct = async (product) => {
    await updateSkuOrderProduct({
      storeId: order?.shopifyStore?.id,
      orderId: product.shopifyOrderId,
      variantId: product.variantId,
      sku: product.sku,
    }).finally(() => {
        setOrderProducts([
          ...orderProducts.filter((prod) => prod.id != product.id),
          { ...product, editMode: false },
        ]);
      });
  };

  const [
    updateFulfillment,
    {
      data: dataUpdateFulfillment,
      isLoading: loadingUpdateFulfillment,
      isSuccess: successUpdateFulfillment,
      isError: errorUpdateFulfillmentEvent,
      error: errorUpdateFulfillmentText,
    },
  ] = useUpdateFulfillmentRequestMutation();

  const { data: products, isLoading: loadingProductCatalog, isSuccess: gettedProductCatalog, isError: isErrorProductCatalog, error: errorProductCatalog } = useGetProductsCatalogQuery(searchText, {
    refetchOnMountOrArgChange: true,
    skip: showProductCatalog
  });

  const onClickRequestFulfill = async () => {
    if(order.shipment) return
    if(order.fullfilmentStatus == "Completato") return

    await updateFulfillment({
      orders: [order],
      toFulfill: true,
      fulfillmentStatusId: FulfillmentStatus.InLavorazione,
    }).finally(() => {
      getOneOrder(orderId);
    });
  };

  const onDeleteFulfillmentRequest = async () => {
    await updateFulfillment({
      orders: [order],
      toFulfill: false,
      fulfillmentStatusId: undefined,
    }).finally(() => {
      getOneOrder(orderId);
    })
  }

  useEffect(() => {
    getOneOrder(orderId);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setUpdatedShipment({
        customerFirstName: order?.customerFirstName,
        customerLastName: order?.customerLastName,
        customerEmail: order?.customerEmail,
        shippingPhone: order?.shippingPhone,
        shippingCountry: order?.shippingCountry,
        shippingCountryCode: order?.shippingCountryCode,
        shippingAddress: order?.shippingAddress,
        shippingCity: order?.shippingCity,
        shippingProvince: order?.shippingProvince,
        shippingZip: order?.shippingZip,
        shippingProvince: order?.shippingProvince,
        shippingProvinceCode: order?.shippingProvinceCode,
        orderNote: order?.orderNote,
        price: order?.price,
      });
      setOrderProducts(
        order?.shopifyOrderProduct?.map((prod) => ({
          ...prod,
          editMode: false,
        }))
      );
      const error = order.errorGeneratingShipment;
    }
  }, [isLoading]);

  useEffect(() => {
    if(isSuccessAddOrderProduct){
      setMutationResponse({
        message: "Prodotto Aggiunto Correttamente all'ordine",
        show: true,
        success: true
      })
    }
    if(isErrorAddOrderProduct){
      setMutationResponse({
        message: errorAddOrderProduct?.data?.message ?? "Errore generico",
        show: true,
        success:false
      })      
    }
  }, [addingOrderProduct])

  useEffect(() => {
    if(successUpdateFulfillment){
      setMutationResponse({
        message: "Evasione Aggiornata Correttamente.",
        show: true,
        success: true
      })
    }
    if(errorUpdateFulfillmentEvent){
      setMutationResponse({
        message: errorUpdateFulfillmentText?.data?.message ?? "Errore generico",
        show: true,
        success:false
      })      
    }
  }, [loadingUpdateFulfillment])

  useEffect(() => {
    if(isSuccessUpdating){
      setMutationResponse({
        message: "Prodotto Aggiornato Correttamente.",
        show: true,
        success: true
      })
    }
    if(isErrorUpdating){
      setMutationResponse({
        message: errorUpdating?.data?.message ?? "Errore generico",
        show: true,
        success:false
      })      
    }
  }, [updatingOrderShipment])

  useEffect(() => {
    if(isSuccessUpdatingSku){
      setMutationResponse({
        message: "Sku Aggiornato Correttamente.",
        show: true,
        success: true
      })
    }
    if(isErrorUpdatingSku){
      setMutationResponse({
        message: errorUpdatingSku?.data?.message ?? "Errore generico",
        show: true,
        success:false
      })      
    }
  }, [updatingSku])  

  useEffect(() => {
    if(isSuccessUpdatingOrderProduct){
      setMutationResponse({
        message: "Quantità Prodotto Aggiornata Correttamente.",
        show: true,
        success: true
      })
    }
    if(isErrorUpdatingOrderProduct){
      setMutationResponse({
        message: errorUpdatingOrderProduct?.data?.message ?? "Errore generico",
        show: true,
        success:false
      })      
    }
  }, [updatingOrderProduct])  

  useEffect(() => {
    if(isSuccessDeleteOrderProduct){
      setMutationResponse({
        message: "Prodotto Eliminato Correttamente.",
        show: true,
        success: true
      })
    }
    if(isErrorDeleteOrderProduct){
      setMutationResponse({
        message: errorDeleteOrderProduct?.data?.message ?? "Errore generico",
        show: true,
        success:false
      })      
    }
  }, [deleteingOrderProduct])  

  if (isLoading || isUninitialized) {
    return <LoadingIcon className="h-4 w-4" />;
  }

  return (
    <main className="flex-col px-2">
      <SimpleNotification 
        message={mutationResponse.message} 
        success={mutationResponse.success}
        show={mutationResponse.show} 
        setShow={() => setMutationResponse({ ...mutationResponse, show: !mutationResponse.show })} 
      />
      <PopupDialog open={showProductCatalog} setOpen={setShowProductCatalog}>
      <SearchBar
        placeholder={"Cerca per nome o sku..."}
        onChange={(search) => {
          if (search == "") {
            setSearchText("%20")
          }
          if (search.length <= 3) return;          
          setSearchText(search);
        }}
      />
      {loadingProductCatalog ? (
        <LoadingIcon color={"text-gray-900"} />
      ) : (
        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-4 lg:grid-cols-3">
        {products?.map((product) => (
  
  
    <div>
    <div
      key={product.id}
      className="relative flex  overflow-hidden rounded-t-lg border border-gray-200 bg-white max-h-24"
    >
      {/* Badge Fuori Stock */}
      {product.stockQuantity === 0 && (
        <div className="absolute top-2 right-2 bg-red-600 text-white text-xs font-bold py-1 px-2 rounded-full z-30">
          FUORI STOCK
        </div>
      )}
      <div className="flex justify-center bg-white aspect-h-4 aspect-w-4 sm:aspect-none sm:h-24 relative">
        <img
          src={product?.productCatalogImage[0]?.src ?? ""}
          alt={product.imageAlt}
          className="object-cover object-center h-full sm:h-full"
        />
      </div>
      <div className="flex flex-1 flex-col space-y-2 p-2 overflow-y-auto">
        <h3 className="text-lg font-medium text-gray-900 flex gap-2 flex-wrap items-center">
          <a href={product.href}>{product.name}</a>
        </h3>
        
      </div>
    </div>
    <div className="flex bg-gray-100 rounded-b-md px-2 py-1 cursor-pointer justify-center bg-green-600 text-white" onClick={() => onAddProductClick(product)}>
          <PlusIcon className="h-6 w-6" />
        </div>
    </div>





        ))}
      </div>
      )}
      </PopupDialog>

      <ModalConfirmationRequest         
        handleConfirm={() => onConfirmDeleteOrderProduct()}
        showModal={confirmationRequest.show} 
        setShowModal={() => setConfirmationRequest({ ...confirmationRequest, show: !confirmationRequest.show })} 
        message={confirmationRequest.message}/>

      <HeaderOrderDetail order={order} />
      {order.errorGeneratingShipment.trim() == "" ? (
        <></>
      ) : (
        <BannerMessage
          message={order.errorGeneratingShipment
            .replace(
              'Client error: `POST https://rdf.spedisci.online/api/v2/shipping/create` resulted in a `400 Bad Request` response:\n{"error":"',
              ""
            )
            .replace("pi\\u00f9", "più")
            .replace('"}', "")
            .replace(" (truncated...)", "")
            .replaceAll("\\", "")}
          background={"bg-red-600"}
        />
      )}
      <div className="mb-2">
      {auth.roles.includes(1) ? (
        <CreateLdvButton
          isLoading={createLoading}
          backgroundClass={
            "bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800"
          }
          onClick={() => createLDV(order).finally(() => getOneOrder(orderId))}
          textButton={"Genera lettera di vettura"}
        />
      ) : auth.roles.includes(2) ? order.orderToFulfill ?           <span className="flex gap-2 items-center">
      {order.orderToFulfill.fulfillmentStatusId ==
        FulfillmentStatus.InLavorazione ? (
          <BadgeComponent
            background={"yellow"}
            color={"gray"}
            text={FulfillmentStatusRadioButtons[FulfillmentStatus.InLavorazione].name}
          />
        ) : order.orderToFulfill.fulfillmentStatusId ==
          FulfillmentStatus.ImpossibileDaEvadere ? (
          <BadgeComponent
            background={"red"}
            color={"gray"}
            text={FulfillmentStatusRadioButtons[FulfillmentStatus.ImpossibileDaEvadere].name}
          />
        ) : order.orderToFulfill.fulfillmentStatusId ==
          FulfillmentStatus.OrdineEvaso ? (
          <BadgeComponent
            background={"green"}
            color={"gray"}
            text={FulfillmentStatusRadioButtons[FulfillmentStatus.OrdineEvaso].name}
          />
        ) : (
          <></>
        )}
      {order.orderToFulfill && order.shipment == null && (
        <Tooltip title={"Elimina Richiesta Evasione"} placement="top">
          <XCircleIcon
            className="h-6 w-6 text-red-700 cursor-pointer"
            onClick={() => onDeleteFulfillmentRequest()}
          />
        </Tooltip>
      )}
    </span> : <CreateLdvButton
      isLoading={false}
      onClick={() => onClickRequestFulfill()}
      textButton={"Richiedi Evasione"}
      /> : <></>}
</div>
      <div className="max-2xl:flex-col-reverse flex gap-4">
        <div className="flex-1">
          <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <h1 className="font-bold text-xl">
                Dati di Spedizione del Cliente
              </h1>
              <div className="flex flex-wrap gap-3">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 flex-1">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Nome
                    </label>
                    <div className="mt-2">
                      <input
                        value={updatedShipment?.customerFirstName ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            customerFirstName: e.target.value,
                          })
                        }
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Cognome
                    </label>
                    <div className="mt-2">
                      <input
                        value={updatedShipment?.customerLastName ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            customerLastName: e.target.value,
                          })
                        }
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        value={updatedShipment?.customerEmail ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            customerEmail: e.target.value,
                          })
                        }
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="telefono"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Telefono
                    </label>
                    <div className="mt-2">
                      <input
                        value={updatedShipment?.shippingPhone ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            shippingPhone: e.target.value,
                          })
                        }
                        id="telefono"
                        name="telefono"
                        type="telefono"
                        autoComplete="telefono"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Nazione
                    </label>
                    <div className="mt-2">
                      <input
                        value={updatedShipment?.shippingCountry ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            shippingCountry: e.target.value,
                          })
                        }
                        required
                        placeholder="es. Italia, Francia, Spagna"
                        type="text"
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Codice Nazione
                    </label>
                    <div className="mt-2">
                      <input
                        value={updatedShipment?.shippingCountryCode ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            shippingCountryCode: e.target.value,
                          })
                        }
                        type="text"
                        placeholder="es. IT, FR, ES"
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Indirizzo di Spedizione
                    </label>
                    <div className="mt-2">
                      <input
                        value={updatedShipment?.shippingAddress ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            shippingAddress: e.target.value,
                          })
                        }
                        type="text"
                        name="street-address"
                        id="street-address"
                        autoComplete="street-address"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2 sm:col-start-1">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Città
                    </label>
                    <div className="mt-2">
                      <input
                        value={updatedShipment?.shippingCity ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            shippingCity: e.target.value,
                          })
                        }
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="address-level2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="postal-code"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Codice Postale
                    </label>
                    <div className="mt-2">
                      <input
                        value={updatedShipment?.shippingZip ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            shippingZip: e.target.value,
                          })
                        }
                        type="text"
                        name="postal-code"
                        id="postal-code"
                        autoComplete="postal-code"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="province"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Provincia
                    </label>
                    <div className="mt-2">
                      <input
                        value={updatedShipment?.shippingProvince ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            shippingProvince: e.target.value,
                          })
                        }
                        required
                        placeholder="es. Italia, Francia, Spagna"
                        type="text"
                        id="province"
                        name="province"
                        autoComplete="province-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="province-code"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Codice Provincia
                    </label>
                    <div className="mt-2">
                      <input
                        value={updatedShipment?.shippingProvinceCode ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            shippingProvinceCode: e.target.value,
                          })
                        }
                        type="text"
                        placeholder="es. IT, FR, ES"
                        id="province-code"
                        name="province-code"
                        autoComplete="province-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="order-note"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Nota Ordine
                    </label>
                    <div className="mt-2">
                      <textarea
                        value={updatedShipment?.orderNote ?? ""}
                        onChange={(e) =>
                          setUpdatedShipment({
                            ...updatedShipment,
                            orderNote: e.target.value,
                          })
                        }
                        type="textarea"
                        id="order-note"
                        name="order-note"
                        autoComplete="province-name"
                        className="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        {" "}
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                disabled={updatingOrderShipment}
                onClick={() => handleEditClick(order)}
                type="submit"
                className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                {updatingOrderShipment ? (
                  <LoadingIcon className="h-4 w-4" />
                ) : (
                  "Modifica"
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="flex-2">
          <ul className="text-sm font-medium flex justify-between items-center">
            <li className="text-gray-900">Tracking number</li>

            <li className="mt-2 text-green-600">
              {(order?.shipment?.trackingNumber ?? "") == "" ? (
                "Spedizione non creata"
              ) : (
                <TrackingButton
                  text={order?.shipment?.trackingNumber ?? ""}
                  trackingNumber={order?.shipment?.trackingNumber ?? ""}
                />
              )}
            </li>
          </ul>

          <ul
            role="list"
            className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
          >
            {orderProducts?.map((product) => (
              <li key={product.id} className="flex space-x-6 py-6">
                <img
                  src={`${imagesUrl}${
                    (product?.productCatalog?.productCatalogImage?.length ??
                      0) > 0
                      ? product?.productCatalog?.productCatalogImage[0]?.src
                      : "noimage.jpg"
                  }`}
                  //alt={product.imageAlt}
                  className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                />
                <div className="flex-auto space-y-1">
                  <h3 className="text-gray-900">
                    <a href={product.href}>{product.name}</a>
                  </h3>
                  <div className="flex items-center gap-2">
                    {(product?.editMode ?? false) == true ? (
                      <>
                        <input
                          value={product.sku}
                          onChange={(e) =>
                            setOrderProducts(
                              orderProducts.map((prod) =>
                                prod.id === product.id
                                  ? { ...product, sku: e.target.value }
                                  : prod
                              )
                            )
                          }
                          type="text"
                          id="sku"
                          name="sku"
                          placeholder="Inserisci lo sku"
                          className="block w-3/2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        />
                        <CheckCircleIcon
                          onClick={() => handleEditSkuProduct(product)}
                          className="h-6 w-6 cursor-pointer hover:shadow-md hover:bg-green-500 hover:text-white hover:p-0.5 rounded-md"
                        />
                      </>
                    ) : (
                      <>
                        <span className="border rounded-md px-2">
                          {(product?.sku ?? "") == ""
                            ? "Inserisci uno sku"
                            : product?.sku}
                        </span>
                        {order.shipment ? <></> : <PencilAltIcon
                          onClick={() =>
                            setOrderProducts(
                              orderProducts.map((prod) =>
                                prod.id === product.id
                                  ? { ...product, editMode: true }
                                  : prod
                              )
                            )
                          }
                          className="h-5 w-5 cursor-pointer"
                        />}
                        
                      </>
                    )}
                  </div>
                  <span className="flex gap-2">Q.tà: 
                    <InputToggleEdit isDisabled={order.shipment} defaultValue={product.quantity} onEndEdit={() => handleEditQtyProduct(product.id)} setValue={(value) => setOrderProducts(
                              orderProducts.map((prod) =>
                                prod.id === product.id
                                  ? { ...product, quantity: value }
                                  : prod
                              )
                            )}/> </span>
                  {order.shipment == null && <div>
                    <button className="uppercase bg-red-700 text-white flex gap-1 items-center px-2 py-1 rounded-md shadow" onClick={() => {
                      setOrderProductToDelete(product)
                      setConfirmationRequest({ ...confirmationRequest, message: `Confermi di voler rimuovere '${product.name}' ?` , show: !confirmationRequest.show })
                    }}><span><TrashIcon className="h-4 w-4" /></span> elimina</button>
                  </div>}
                  
                </div>
                <div className="flex-none font-medium text-gray-900">
                  {/* <InputToggleEdit isDisabled={order.shipment} defaultValue={product.price} onEndEdit={() => handleEditQtyProduct(product.id)} setValue={(value) => setOrderProducts(
                    orderProducts.map((prod) =>
                      prod.id === product.id
                        ? { ...product, price: value }
                        : prod
                    )
                  )}/> */}
                </div>
              </li>
            ))}
          </ul>
          {order.shipment == null && <section className="w-full flex justify-end">
            <button className="uppercase rounded-md bg-gray-100 px-3 py-1 my-1 flex gap-1 items-center" onClick={() => setShowProductCatalog(true)}><span><PlusIcon className="h-4 w-4" /></span>prodotto</button>
            </section>}
          
          <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
            <div className="flex justify-between">
              <dt></dt>
              <dd className="text-gray-900">COSTI</dd>
            </div>
            <div className="flex justify-between">
              <dt>
                Prodotti ({order?.shipment?.shipmentProducts?.length ?? 0})
              </dt>
              <dd className="font-bold text-md text-red-600 ">
                -
                {order?.shipment?.shipmentProducts?.reduce(
                  (accumulator, product) => {
                    return (
                      accumulator +
                      (product?.userCost ?? 0) * (product?.qty ?? 0)
                    );
                  },
                  0
                )}
              </dd>
            </div>

            <div className="flex justify-between">
              <dt>Spedizione</dt>
              <dd className="font-bold text-md text-red-600">
                -{order?.shipment?.userCost ?? 0}€
              </dd>
            </div>

            <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
              <dt className="text-base">Tot. Ordine Shopify</dt>
              <dd className="font-bold text-lg text-gray-600">

                  <InputToggleEdit
                    isDisabled={order.shipment}
                    defaultValue={updatedShipment.price}
                    Icon={CurrencyEuroIcon}
                    setValue={(value) =>
                      setUpdatedShipment({ ...updatedShipment, price: value })
                    }
                    onEndEdit={(isToEdit) => {
                      if (isToEdit) {
                        handleEditClick(order);
                        return;
                      }
                      setUpdatedShipment({
                        ...updatedShipment,
                        price: order.price,
                      });
                    }}
                  />
                
              </dd>
            </div>

            {/* <div className="flex items-center justify-between  text-gray-900">
              <dt className="text-base text-gray-600">
                Incasso
                <br />
                <span className="text-sm underline">
                  (se ordine consegnato)
                </span>
              </dt>
              <dd className="font-bold text-lg text-green-600">
                +
                {order?.shipment == null
                  ? 0
                  : (
                      (order?.price ?? 0) -
                      order?.shipment?.shipmentProducts?.reduce(
                        (accumulator, product) => {
                          return (
                            accumulator +
                            (product?.userCost ?? 0) * (product?.qty ?? 0)
                          );
                        },
                        0
                      ) -
                      (order?.shipment?.userCost ?? 0)
                    ).toFixed(2)}
                €
              </dd>
            </div> */}
          </dl>
        </div>
      </div>
    </main>
  );
}

export default ShopifyOrderDetailPage;

const HeaderOrderDetail = ({ order }) => {
  const auth = useSelector((state) => state.auth);
  return (
    <div className="px-4 py-10 sm:px-6 lg:px-8">
      <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0">
        <div className="flex items-center gap-x-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12 border rounded-full p-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z"
            />
          </svg>

          <h1>
            <div className="text-sm leading-6 text-gray-500 flex flex-wrap gap-1">
              ORDINE{" "}
              <span className="text-gray-700">#{order?.orderNumber ?? ""}</span>
              {(order?.fullfilmentStatus ?? "") !== "" && (
                <span
                  className={`inline-flex items-center gap-x-0.5 rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ${
                    (order.fullfilmentStatus ?? "") == "Completato"
                      ? "bg-green-50 text-green-700 ring-green-600/10"
                      : "bg-orange-50 text-orange-700 ring-orange-600/10"
                  } `}
                >
                  {order.fullfilmentStatus}
                </span>
              )}
            </div>
            <div className="mt-1 text-base font-semibold leading-6 text-gray-900">
              {order?.shopifyStore?.storeName ?? ""}
            </div>
          </h1>
        </div>
        <div className="flex items-center gap-x-4 sm:gap-x-6"></div>
      </div>
      <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
        <div className="mt-2 flex items-center text-sm text-gray-500">
          <CalendarIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
            aria-hidden="true"
          />
          Effettuato il {MyDateTime.dateTimeToString(order?.createdDate ?? "")}
        </div>

        {auth.roles.includes(1) ? (
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <UserIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
              aria-hidden="true"
            />
            Cliente:
            <span className="font-bold ml-1">
              {`${order?.registeredUser?.name ?? ""} ${
                order?.registeredUser?.surname ?? ""
              }`}
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
