import React from "react";
import { LoadingIcon } from "../../custom/LoadingIcon";
import { useGetProductsOrderTableQuery } from "../../../services/orderApi";
import TextCopyToClipboard from "../../catalog/TextCopyToClipboard";

function ProductsOrderTable({ orderId, showOrderProducts }) {
  const {
    data: productsOrder,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetProductsOrderTableQuery(
    { orderId: orderId },
    { skip: !showOrderProducts }
  );
  return isLoading ? (
    <LoadingIcon className="h-4 w-4" />
  ) : isSuccess ? (
    // <table className="min-w-full divide-y divide-gray-300">
    <table className="min-w-full border divide-y rounded-md">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-1.5 pl-2 pr-1 text-left text-sm font-semibold text-gray-900 sm:pl-1"
          >
            nome
          </th>
          <th
            scope="col"
            className="px-1 py-1.5 text-left text-sm font-semibold text-gray-900"
          >
            sku
          </th>
          <th
            scope="col"
            className="px-1 py-1.5 text-left text-sm font-semibold text-gray-900"
          >
            q.tà
          </th>
          {/* <th
            scope="col"
            className="px-1 py-1.5 text-left text-sm font-semibold text-gray-900"
          >
            q.tà in magazzino
          </th> */}
        </tr>
      </thead>
      <tbody>
        {productsOrder?.map((prod, index) => (
          <tr key={`${prod.name}-${index}`}>
            <td className="py-2 pl-2 pr-1 text-sm font-medium text-gray-900 sm:pl-1">
              {prod.name}
            </td>
            <td className="px-1 py-2 text-sm text-gray-500">
              <TextCopyToClipboard text={prod.sku} textHover={"COPIA SKU"} />
            </td>
            <td className="px-1 py-2 text-sm text-gray-500">{prod.quantity}</td>
            {/* <td className="px-1 py-2 text-sm text-gray-500">
              {prod.quantityWarehouse}
            </td> */}
          </tr>
        )) ?? <></>}
      </tbody>
    </table>
  ) : (
    <></>
  );
}

export default ProductsOrderTable;
