import React, { useEffect } from "react";



const IubendaScript = () => {
  useEffect(() => {
    // Creare lo script Iubenda di configurazione
    const iubendaConfigScript = document.createElement("script");
    iubendaConfigScript.type = "text/javascript";
    iubendaConfigScript.innerHTML = `
    var _iub = _iub || [];
    _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"floatingPreferencesButtonDisplay":"bottom-right","perPurposeConsent":true,"siteId":3683621,"whitelabel":false,"cookiePolicyId":72797689,"lang":"it","banner":{"acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#0A513C","acceptButtonDisplay":true,"backgroundColor":"#96BF3C","brandBackgroundColor":"#00000000","closeButtonDisplay":false,"customizeButtonCaptionColor":"#000000","customizeButtonColor":"#96BF3C00","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"logo":"https://images.codfacile.com/logo-bianco-nero.png","ownerName":"www.codfacile.com","position":"float-bottom-center","rejectButtonCaptionColor":"#FFFFFF","rejectButtonColor":"#96BF3C","rejectButtonDisplay":true,"showTitle":false,"showTotalNumberOfProviders":true,"theme":"mint-light_trend","textColor":"#000000"}};
    `;
    document.head.appendChild(iubendaConfigScript);

    // Creare lo script autoblocking di Iubenda
    const iubendaAutoBlockingScript = document.createElement("script");
    iubendaAutoBlockingScript.type = "text/javascript";
    iubendaAutoBlockingScript.src = "https://cs.iubenda.com/autoblocking/3683621.js";
    document.head.appendChild(iubendaAutoBlockingScript);

    // Creare lo script principale di Iubenda
    const iubendaMainScript = document.createElement("script");
    iubendaMainScript.type = "text/javascript";
    iubendaMainScript.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
    iubendaMainScript.async = true;
    iubendaMainScript.charset = "UTF-8";
    document.head.appendChild(iubendaMainScript);

    // Cleanup function per rimuovere gli script quando il componente viene smontato
    return () => {
      document.head.removeChild(iubendaConfigScript);
      document.head.removeChild(iubendaAutoBlockingScript);
      document.head.removeChild(iubendaMainScript);
    };
  }, []);

  return null; // Non renderizza nulla
};

export default IubendaScript;
