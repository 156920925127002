import { userApi } from "./userApi";

const ebayIntegrationApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    generateTokens: builder.mutation({
      query: ({ code }) => ({
        url: `ebay/generate-tokens`,
        method: "POST",
        body: { code },
      }),
    }),
    getAllEbayIntegration: 
    builder.query({
      query: () => ({
        url: `ebay/get-all`,
        method: 'POST',
        //body: { search: name  },
      })
    // builder.mutation({
    //   query: () => ({
    //     url: `ebay/get-all`,
    //     method: "POST",
    //   }),
     }),
    updateEbayIntegration: builder.mutation({
      query: (ebayIntegration) => ({
        url: `ebay/update`,
        method: "POST",
        body: { ebayIntegration }
      }),
    }),

    addProductToEbay: builder.mutation({
      query: ({ productCatalog, ebayStoreId }) => ({
        url: `ebay/add-product-to-ebay`,
        method: "POST",
        body: { productCatalog, ebayStoreId }
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGenerateTokensMutation, useGetAllEbayIntegrationQuery, useUpdateEbayIntegrationMutation, useAddProductToEbayMutation } = ebayIntegrationApi;
