import { Outlet, useLocation } from "react-router-dom";
import SideBarMenu from "./components/SideBarMenu";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PopupDialog from "./components/custom/PopupDialog";
import PrivacyPolicyText from "./components/PrivacyPolicyText";
import PopupPrivacyPolicy from "./components/PopupPrivacyPolicy";
import { useSavePrivacyPolicyMutation } from "./services/privacyPolicyApi";
import PopupTermsAndConditions from "./components/PopupTermsAndConditions";
import TermsAndConditionsText from "./components/TermsAndConditionsText";
const Layout = () => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);
  const [openPopupPrivacy, setOpenPopupPrivacy] = useState(false);
  useEffect(() => {
    if (auth.privacyPolicy == false) {
      setOpenPopupPrivacy(true);
      return;
    }
    if (auth.termsAndConditions == false) {
      setOpenTermsAndConditions(true);
      return;
    }
  }, [auth]);

  return (
    <main className="App scroll-smooth">
      <PopupPrivacyPolicy open={openPopupPrivacy} setOpen={setOpenPopupPrivacy}>
        <div className="max-h-96 max-w-96" id="container-popup-policy">
          <PrivacyPolicyText />
        </div>
      </PopupPrivacyPolicy>
      <PopupTermsAndConditions
        open={openTermsAndConditions}
        setOpen={setOpenTermsAndConditions}
      >
        <div className="max-h-96 max-w-96" id="container-popup-policy">
          <TermsAndConditionsText />
        </div>
      </PopupTermsAndConditions>
      {(auth?.accessToken ?? "") !== "" &&
      location.pathname !== "/" &&
      location.pathname !== "/login" &&
      !location.pathname.includes("/register") &&
      location.pathname !== "/pricing" ? (
        <SideBarMenu>
          <Outlet />
        </SideBarMenu>
      ) : (
        <Outlet />
      )}
    </main>
  );
};

export default Layout;
