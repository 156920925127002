import { userApi } from "./userApi";

const rechargeBalanceApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    // GET: Ottieni tutti i record di recharge_balance
    getAllRechargeBalances: builder.query({
      query: (userId) => `recharge-balance/${userId}`,
      providesTags: ["RechargeBalance"],
    }),

    // POST: Crea un nuovo record di recharge_balance
    createRechargeBalance: builder.mutation({
      query: ({ registeredUserId, amount }) => ({
        url: `recharge-balance`,
        method: "POST",
        body: { registeredUserId, amount },
      }),
      invalidatesTags: ["RechargeBalance"], // Invalidiamo il tag per aggiornare la cache
    }),

    // PUT: Aggiorna un record esistente di recharge_balance
    updateRechargeBalance: builder.mutation({
      query: ({ id, amount }) => ({
        url: `recharge-balance/${id}`,
        method: "PUT",
        body: { amount },
      }),
      invalidatesTags: ["RechargeBalance"], // Invalidiamo il tag per aggiornare la cache
    }),

    // DELETE: Elimina un record di recharge_balance
    deleteRechargeBalance: builder.mutation({
      query: (id) => ({
        url: `recharge-balance/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RechargeBalance"], // Invalidiamo il tag per aggiornare la cache
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllRechargeBalancesQuery,
  useLazyGetAllRechargeBalancesQuery,
  useCreateRechargeBalanceMutation,
  useUpdateRechargeBalanceMutation,
  useDeleteRechargeBalanceMutation,
} = rechargeBalanceApi;
