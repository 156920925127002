import { Link } from "react-router-dom";
import logo from "../images/logo_trasp.png";
import { useState } from "react";
import { LoadingIcon } from "./custom/LoadingIcon";
import { useCheckAliasCodeQuery } from "../services/promotionApi";
import { XIcon } from "@heroicons/react/outline";
import { validatePassword } from "../functions/validate_password";

export const InputField = ({
  id,
  inputRef,
  autofocus = false,
  label,
  type = "text",
  onChange,
  required = true,
  autoComplete,
  value,
  step,
  disabled = false,
  placeholder,
  onEnter,
}) => {
  function enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      onEnter && onEnter();
    }
  }
  return (
    <div className="flex-1">
      <div className="flex items-center justify-between">
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
      </div>
      {/* <div className="mt-2"> */}
      <div>
        {value == undefined ? (
          <input
            ref={inputRef}
            autoFocus={autofocus}
            placeholder={placeholder ?? ""}
            disabled={disabled}
            onChange={onChange}
            onKeyDown={enterPressed.bind(this)}
            id={id}
            name={id}
            type={type}
            autoComplete={autoComplete}
            required={required}
            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
            step={step}
          />
        ) : (
          <input
            ref={inputRef}
            autoFocus={autofocus}
            placeholder={placeholder ?? ""}
            disabled={disabled}
            value={value}
            onChange={onChange}
            onKeyDown={enterPressed.bind(this)}
            id={id}
            name={id}
            type={type}
            autoComplete={autoComplete}
            required={required}
            step={step}
            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
          />
        )}
      </div>
    </div>
  );
};

export const RegistrationForm = ({
  isLoading,
  handleSignUp,
  handlePassword,
  handleEmail,
  handleName,
  handleSurname,
  handleConfirmPassword,
  handleBusinessName,
  handleVatNumber,
  handleFiscalCode,
  handleAddress,
  handlePhone,
  handleCity,
  handleZipCode,
  handleProvince,
  handleNation,
  handlePrivacy,
  handleTermsAndConditions,
  handleIban,
  promotionCode,
}) => {
  const [passwordError, setPasswordError] = useState("");

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    if (!validatePassword(value)) {
      setPasswordError(
        "La password deve avere almeno 8 caratteri, includere un carattere speciale e una lettera maiuscola."
      );
    } else {
      setPasswordError("");
    }
    handlePassword(e);
  };
  const {
    data: promo,
    isLoading: checkingAliasCode,
    isSuccess: verifiedAliasCode,
    isError: unverifiedAliasCode,
    errorCheckingText,
  } = useCheckAliasCodeQuery(promotionCode, {
    skip: promotionCode == undefined,
  });

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto w-60" src={logo} alt="Your Company" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Registra il tuo account
        </h2>
        <p className="m-0 text-center">
          Crea il tuo account per iniziare a vendere con noi.
        </p>
      </div>
      {verifiedAliasCode ? (
        (promo?.promo?.id ?? 0) !== 0 ? (
          <div className="pointer-events-none sm:flex sm:justify-center sm:px-6 mt-3">
            <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-green-facile px-6 py-2.5 rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
              <p className="text-sm leading-6 text-white">
                <a href="#">
                  <strong className="font-semibold">Congratulazioni</strong>
                  <svg
                    viewBox="0 0 2 2"
                    aria-hidden="true"
                    className="mx-2 inline h-0.5 w-0.5 fill-current"
                  >
                    <circle r={1} cx={1} cy={1} />
                  </svg>
                  Il tuo codice promo <b>{promo.promo.aliasCode}</b> è valido.
                  {(promo.promo.amountOff ?? 0) == 0 &&
                  (promo.promo.percentOff ?? 0) == 0
                    ? ""
                    : "Lo sconto verrà applicato automaticamente al checkout."}
                </a>
              </p>
            </div>
          </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-2xl">
        <form
          className="space-y-6"
          onSubmit={(e) =>
            passwordError == "" ? handleSignUp(e) : e.preventDefault()
          }
        >
          <p className="text-2xl font-bold m-0">Dati d'accesso</p>
          <div className="sm:flex gap-5">
            <InputField
              id="name"
              label="Nome"
              onChange={handleName}
              required={true}
            />
            <InputField
              id="surname"
              label="Cognome"
              onChange={handleSurname}
              required={true}
            />
          </div>
          <InputField
            required={true}
            id="email"
            label="Email"
            type="email"
            onChange={handleEmail}
            autoComplete="email"
          />
          <span className="text-gray-400">
            Verificare la correttezza della mail, questa verrà utilizzata per la
            gestione dell'abbonamento.
          </span>
          <div>
            <div className="sm:flex gap-5">
              <InputField
                required={true}
                id="password"
                label="Password"
                type="password"
                onChange={handlePasswordChange}
                autoComplete="current-password"
              />
              <InputField
                required={true}
                id="confirm-password"
                label="Conferma Password"
                type="password"
                onChange={handleConfirmPassword}
                autoComplete="current-password"
              />
            </div>
            {passwordError !== "" && (
              <span className="text-red-400">{passwordError}</span>
            )}
          </div>
          <p className="text-2xl font-bold m-0">Dati di fatturazione</p>
          <div className="sm:flex gap-5 mt-0">
            <InputField
              id="business-name"
              label="Ragione Sociale (opzionale)"
              onChange={handleBusinessName}
              required={false}
            />
            <InputField
              id="vat-number"
              label="Partita IVA (opzionale)"
              onChange={handleVatNumber}
              required={false}
            />
            <InputField
              id="fiscal-code"
              label="Codice fiscale"
              onChange={handleFiscalCode}
            />
          </div>
          <InputField
            id="iban"
            label="IBAN"
            onChange={handleIban}
            autoComplete="iban"
            required={true}
          />
          <span className="text-gray-400">
            L'IBAN sul quale riceverai i bonifici.
          </span>
          <div className="sm:flex gap-5">
            <InputField
              id="address"
              label="Indirizzo"
              onChange={handleAddress}
            />
            <InputField
              id="phone"
              label="Cellulare"
              onChange={handlePhone}
              required={false}
            />
          </div>
          <div className="sm:flex gap-5">
            <InputField id="city" label="Città" onChange={handleCity} />
            <InputField id="zip-code" label="Cap" onChange={handleZipCode} />
            <InputField
              id="province"
              label="Provincia"
              onChange={handleProvince}
              placeholder={"es. Firenze, Pavia"}
            />
            <InputField
              id="nation"
              label="Nazione"
              onChange={handleNation}
              placeholder={"es. Italia"}
            />
          </div>
          <div className="flex gap-3">
            <div className="flex items-center">
              <input
                required={true}
                onChange={handlePrivacy}
                id="accept-privacy-policy"
                name="accept-privacy-policy"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor="accept-privacy-policy"
                className="ml-3 block text-sm leading-6 text-gray-900"
              >
                Accetto il consenso alla{" "}
                <a
                  href="https://www.codfacile.com/policies/privacy-policy"
                  className="text-green-facile"
                >
                  privacy
                </a>
              </label>
            </div>
            <div className="flex items-center">
              <input
                required={true}
                onChange={handleTermsAndConditions}
                id="accept-terms-conditions"
                name="accept-terms-conditions"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor="accept-terms-conditions"
                className="ml-3 block text-sm leading-6 text-gray-900"
              >
                Accetto i{" "}
                <a
                  href="https://codfacile.com/termini-e-condizioni-di-servizio"
                  className="text-green-facile"
                >
                  termini e condizioni
                </a>
              </label>
            </div>
          </div>

          <button
            disabled={isLoading}
            type="submit"
            className="flex w-full justify-center rounded-md bg-green-facile px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-facile focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-facile"
          >
            {isLoading ? <LoadingIcon color={"text-white"} /> : "Registrati"}
          </button>
          <span className="mt-3 leading-6 text-gray-500 text-sm">
            Hai già un account?{" "}
            <Link
              to={"/login"}
              className={"text-green-facile hover:text-green-facile"}
            >
              Accedi
            </Link>
          </span>
        </form>
      </div>
    </div>
  );
};
